import axios from 'axios';
import { useState } from 'react';
import { useAsyncEffect } from 'rooks';

export function useFetchCloverOrderTypes( gatewayId?: string, companyId?: string ): ( [ boolean, any[], any[] ] ) {
	
	const [ orderTypes, setOrderTypes ] = useState( [] );
	const [ orderTypesLabels, setOrderTypesLabels ] = useState( [] );
	
	const [ loading, setLoading ] = useState( true );
	
	useAsyncEffect( async () => {
		try {
			if ( gatewayId || companyId ) {
				const { data } = await axios.post( `/api/processor/manage/getOrderTypes`, {
					id: gatewayId,
					companyId,
				} );
				
				if ( data.data ) {
					setOrderTypes( data.data );
					setOrderTypesLabels( data.data.map( ( orderType ) => orderType.label ) );
					setLoading( false );
					
				}
			} else {
				setLoading( false );
			}
		} catch {
			setLoading( false );
			
		}
		
	}, [ gatewayId, companyId ] );
	
	return [ loading, orderTypes || [], orderTypesLabels || [] ];
};
